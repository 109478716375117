import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import BusinessOutcomesIcon from '../../assets/images/product/icons/business-outcomes.svg'
import DeveloperExperienceIcon from '../../assets/images/product/icons/developer-experience.svg'
import DeveloperProductivityIcon from '../../assets/images/product/icons/developer-productivity.svg'
import FlexibleConfigurationIcon from '../../assets/images/product/icons/flexible-configuration.svg'
import SecurityFirstIcon from '../../assets/images/product/icons/security-first.svg'
import SlackIcon from '../../assets/images/product/icons/slack.svg'
import MetaImage from '../../assets/images/product/overview/product-overview-meta.png'
import tag from '../../assets/images/tags/product-overview-tag.svg'
import Layout from '../../components/Layout'
import CTABlock from '../../components/blocks/CTABlock'
import ColumnsBlock, { gridSpacing } from '../../components/blocks/ColumnsBlock'
import HeroBlock from '../../components/blocks/HeroBlock'
import LeadBlock from '../../components/blocks/LeadBlock'
import VideoBlock from '../../components/blocks/VideoBlock'
import Block from '../../components/primitives/Block'
import Box from '../../components/primitives/Box'
import Row from '../../components/primitives/Row'
import ShadowFrame from '../../components/primitives/ShadowFrame'
import Stack from '../../components/primitives/Stack'
import Blurb from '../../components/system/Blurb'
import QuoteBlock from '../../components/blocks/QuoteBlock'
import GlobalStyleReset from '../../components/system/GlobalStyleReset'
import { responsiveScale } from '../../styles/helpers'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/product/overview/hero-bg.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    videoThumbnail: file(
      relativePath: {
        eq: "images/product/overview/product-overview-thumbnail.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1280)
      }
    }
    investmentBalance: file(
      relativePath: { eq: "images/product/overview/investment-balance.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 680)
      }
    }
    initiatives: file(
      relativePath: { eq: "images/product/overview/initiatives.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 480)
      }
    }
    softwareCapitalization: file(
      relativePath: {
        eq: "images/product/overview/software-capitalization.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
    engineeringMetrics: file(
      relativePath: { eq: "images/product/overview/engineering-metrics.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
    workLog: file(
      relativePath: { eq: "images/product/overview/work-log.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
    developerOverview: file(
      relativePath: { eq: "images/product/overview/developer-overview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 412)
      }
    }
    josep: file(relativePath: { eq: "images/quotes/josep.png" }) {
      childImageSharp {
        gatsbyImageData(width: 64)
      }
    }
    surveys: file(relativePath: { eq: "images/product/overview/surveys.png" }) {
      childImageSharp {
        gatsbyImageData(width: 640)
      }
    }
    workingAgreements: file(
      relativePath: { eq: "images/product/overview/working-agreements.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 330)
      }
    }
    ciCdInsights: file(
      relativePath: { eq: "images/product/overview/ci-cd-insights.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 736)
      }
    }
    builtToScale: file(
      relativePath: { eq: "images/product/overview/built-to-scale.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 736)
      }
    }
    easyToIntegrate: file(
      relativePath: { eq: "images/product/overview/easy-to-integrate.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 736)
      }
    }
    designedForWholeOrg: file(
      relativePath: { eq: "images/product/overview/designed-for-whole-org.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 612)
      }
    }
  }
`

const ProductOverviewPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="Product overview"
      variant="dark"
      isNew
      description="Swarmia combines research-backed insights with tools that help modern software teams improve business outcomes, developer productivity, and developer experience."
      metaImage={MetaImage}
    >
      <GlobalStyleReset>
        <HeroBlock
          tag={tag}
          title={
            <>Software engineering intelligence for modern organizations</>
          }
          content="Systematically improve business outcomes, developer productivity, and developer experience with Swarmia."
          backgroundImage={getImage(data.backgroundImage)!}
        />
        <LeadBlock
          heading={<>Here&#x2019;s how it works</>}
          content="Watch our 12-minute product overview to understand how Swarmia gives every level of the engineering organization the insights they need to ship better software faster."
        />
        <VideoBlock
          paddingTop={responsiveScale(64)}
          paddingBottom={responsiveScale(48)}
          thumbnail={getImage(data.videoThumbnail)!}
          videoId="ZHcQO20bL4w"
        />
        <LeadBlock
          icon={BusinessOutcomesIcon}
          heading={<>Predictably deliver on planned business outcomes</>}
          content="Steer your business in the right direction with the help of investment insights and real-time visibility into project delivery."
          link={{
            title: 'Read more about business outcomes',
            href: '/product/business-outcomes/',
          }}
        />
        <ColumnsBlock
          paddingTop={responsiveScale(64)}
          paddingBottom={responsiveScale(64)}
          columns={[
            <Blurb
              key="1"
              title="Investment balance"
              text="Understand where engineering time goes and make informed investment decisions."
              href="/product/investment-balance/"
              target="_self"
              image={getImage(data.investmentBalance)}
            />,
            <Blurb
              key="2"
              title="Initiatives"
              text="Quickly spot the projects that are about to slip and offer support to teams that need it."
              href="/product/initiatives/"
              target="_self"
              image={getImage(data.initiatives)}
              clipImage
            />,
            <Blurb
              key="3"
              title="Software capitalization"
              text="Create flexible and audit-ready software cost capitalization reports."
              href="/product/software-capitalization/"
              target="_self"
              image={getImage(data.softwareCapitalization)}
              clipImage
            />,
          ]}
          flex={[2, 1, 1]}
        />
        <QuoteBlock person="maryanna" />
        <LeadBlock
          icon={DeveloperProductivityIcon}
          heading={
            <>
              Boost developer productivity with reliable data and intuitive
              visualizations
            </>
          }
          content="Unblock the flow with healthy team-level productivity metrics (including DORA and SPACE) and actionable visualizations."
          link={{
            title: 'Read more about developer productivity',
            href: '/product/developer-productivity/',
          }}
        />
        <ColumnsBlock
          paddingTop={responsiveScale(64)}
          paddingBottom={responsiveScale(64)}
          columns={[
            <Blurb
              key="1"
              title="Engineering metrics"
              text="Measure healthy productivity metrics and drill into aggregates to find and fix the root causes of issues."
              href="/product/engineering-metrics/"
              target="_self"
              image={getImage(data.engineeringMetrics)}
              imageAlign="right"
            />,
            <Blurb
              key="2"
              title="Work log"
              text="Identify and fix common teamwork anti-patterns, including siloing and too much work in progress."
              href="/product/work-log/"
              target="_self"
              image={getImage(data.workLog)}
            />,
            <Blurb
              key="3"
              title="Developer overview"
              text="Visualize the work of software engineers to allow better conversations between managers and their direct reports."
              href="/product/developer-overview/"
              target="_self"
              image={getImage(data.developerOverview)}
            />,
          ]}
        />
        <QuoteBlock person="pau" />
        <LeadBlock
          icon={DeveloperExperienceIcon}
          heading={
            <>
              Create a better developer experience with dev tooling and feedback
              from your engineers
            </>
          }
          content="Automate feedback loops, reduce wait times, and correlate system metrics with sentiment data from your developers."
          link={{
            title: 'Read more about developer experience',
            href: '/product/developer-experience/',
          }}
        />
        <Block paddingTop={responsiveScale(64)}>
          <Row
            maxWidth="largeBlock"
            space={gridSpacing}
            justifyContent="center"
            css={{ margin: 'auto' }}
            flexDirection={{ xs: 'column', md: 'row' }}
          >
            <Box flex={1}>
              <Stack key="1" space={gridSpacing}>
                <Blurb
                  title="Developer surveys"
                  text="Run targeted developer surveys to complement system metrics with feedback from your engineers. "
                  href="/product/surveys/"
                  target="_self"
                  image={getImage(data.surveys)}
                  isLarge
                />
                <JosepQuote />
              </Stack>
            </Box>
            <Box flex={1}>
              <Stack key="2" space={gridSpacing}>
                <Row
                  width="100%"
                  space={gridSpacing}
                  flexDirection={{
                    xs: 'column',
                    sm: 'row',
                    md: 'column',
                    lg: 'row',
                  }}
                >
                  <Box flex={1}>
                    <Blurb
                      title="Working agreements"
                      text="Put continuous improvement on autopilot with team-wide working agreements."
                      href="/product/working-agreements"
                      target="_self"
                      image={getImage(data.workingAgreements)}
                      clipImage
                    />
                  </Box>
                  <Box flex={1}>
                    <Blurb
                      icon={SlackIcon}
                      title="Slack notifications"
                      text="Automate code review reminders and stay on top of ongoing work with Swarmia’s two-way GitHub-Slack integration."
                      href="/github-slack-integration/"
                      target="_self"
                    />
                  </Box>
                </Row>
                <Blurb
                  title="CI insights"
                  text="Dramatically reduce CI wait times and costs with detailed CI insights."
                  href="/product/ci-cd-insights/"
                  target="_self"
                  image={getImage(data.ciCdInsights)}
                  imageAlign="right"
                  isLarge
                  clipImage
                />
              </Stack>
            </Box>
          </Row>
        </Block>
        <LeadBlock
          heading="Enterprise-grade features meet lightweight setup"
          content="Swarmia combines the best of both worlds: capabilities that meet enterprise expectations with flexible configuration that only takes minutes."
        />
        <Block
          paddingTop={responsiveScale(64)}
          paddingBottom={responsiveScale(92)}
        >
          <Row
            maxWidth="largeBlock"
            space={gridSpacing}
            justifyContent="center"
            marginX="auto"
            marginBottom={gridSpacing}
            flexDirection={{ xs: 'column', md: 'row' }}
          >
            <Box flex={1}>
              <Blurb
                title="Built to scale"
                text="Swarmia fits the needs of modern organizations from startups to enterprises."
                image={getImage(data.builtToScale)}
                isLarge
              />
            </Box>
            <Box flex={1}>
              <Blurb
                title="Easy to integrate"
                text="Swarmia works with the tools your teams are already using: from issue tracker to source code hosting and chat."
                image={getImage(data.easyToIntegrate)}
                imageAlign="right"
                isLarge
              />
            </Box>
          </Row>
          <Row
            maxWidth="largeBlock"
            space={gridSpacing}
            justifyContent="center"
            marginX="auto"
            flexDirection={{ xs: 'column', md: 'row' }}
          >
            <Row
              space={gridSpacing}
              flexDirection={{
                xs: 'column',
                sm: 'row',
              }}
              flex={1}
            >
              <Box flex={1}>
                <Blurb
                  title="Designed security first"
                  text="We&#x2019;re SOC 2 Type 2 and GDPR compliant and committed to keeping your data secure."
                  href="/security/"
                  target="_self"
                  imageAlign="right"
                  icon={SecurityFirstIcon}
                />
              </Box>
              <Box flex={1}>
                <Blurb
                  title="Flexible configuration, no set up fees"
                  text="You can either set up Swarmia on your own or get help from our customer success team — at no extra cost."
                  imageAlign="right"
                  icon={FlexibleConfigurationIcon}
                />
              </Box>
            </Row>
            <Box flex={1}>
              <Blurb
                title="Designed for the whole organization"
                text="In addition to insights and reports for the engineering leadership, Swarmia gives teams the tools they need to ship faster."
                image={getImage(data.designedForWholeOrg)}
                isLarge
              />
            </Box>
          </Row>
        </Block>
        <CTABlock
          title={<>Start driving engineering effectiveness&nbsp;today.</>}
        />
      </GlobalStyleReset>
    </Layout>
  )
}

export default ProductOverviewPage

const JosepQuote = () => {
  const { josep } = useStaticQuery(query)

  return (
    <ShadowFrame
      paddingTop={40}
      paddingRight={40}
      paddingBottom={32}
      paddingLeft={40}
    >
      <Stack space={20}>
        <Box
          fontFamily="FactorA"
          fontSize={24}
          lineHeight="150%"
          fontWeight={700}
          maxWidth={500}
        >
          “Swarmia’s GitHub Slack integration is one of the reasons our
          engineers really love the product.”
        </Box>
        <Row space={16} alignItems="center">
          <GatsbyImage image={getImage(josep)!} alt="Josep Jaume Rey" />
          <Stack>
            <Box font="small" fontWeight={700}>
              Josep Jaume Rey
            </Box>
            <Box font="small">Senior Engineering Director at Factorial</Box>
          </Stack>
        </Row>
      </Stack>
    </ShadowFrame>
  )
}
